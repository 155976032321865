import React, { useEffect, useState } from "react";
import demo from "./utils/assets/demoData/demo";
import "./App.css";
import logo from './utils/assets/images/logo.png'

const App = () => {
  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);

  useEffect(() => {
    const arr = demo.filter((value) => {
      var re = new RegExp(search, "gi");
      if (
        value.first_name.match(re) ||
        value.last_name.match(re) ||
        value.email.match(re) ||
        value.company.match(re) ||
        value.issue_date.match(re) ||
        value.birth_date.match(re)
      ) {
        return value;
      }
    });
    if (search === "") {
      setResults([]);
    } else {
      setResults(arr);
    }
  }, [search]);

  return (
    <>
      <nav class="navbar" style={{backgroundColor:'#F1200A'}}>
        <div class="container-fluid">
        <img src={logo} alt="" width="50" height="50"/>
        </div>
      </nav>
      <div className="container">
        <div class="input-group mt-3">
          <span class="input-group-text" id="basic-addon1">
            Search
          </span>
          <input
            type="text"
            class="form-control"
            placeholder="Enter name or company name or certificate issue date"
            aria-label="Username"
            aria-describedby="basic-addon1"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <p style={{ fontSize: 12 }}>
          Enter First Name or Last Name or Company Name or Email Address or
          Certificate Issue date or Birth date
        </p>
        {results.map((data, index) => {
          return (
            <div className="card" style={{ padding: 20, margin: 10 }}>
              <div>
                First Name: <b>{data.first_name}</b>{" "}
              </div>
              <div>
                Last Name: <b>{data.last_name}</b>{" "}
              </div>
              <div>
                email: <b>{data.email}</b>
              </div>
              <div>
                Company: <b>{data.company}</b>{" "}
              </div>
              <div>
                Certificate Issue Date: <b>{data.issue_date}</b>{" "}
              </div>
              <div>
                Birth Date: <b>{data.birth_date}</b>{" "}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default App;
